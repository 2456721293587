import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { RiMenu2Fill,RiRefreshLine, RiPinterestLine, RiLineFill, RiFacebookLine, RiTwitterLine, RiHome2Line, RiArrowRightLine, RiArrowLeftLine, RiCalendar2Line } from "react-icons/ri"
import { ArticleJsonLd } from 'gatsby-plugin-next-seo';
import { BreadcrumbJsonLd } from 'gatsby-plugin-next-seo';
import scrollTo from 'gatsby-plugin-smoothscroll';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';

import Layout from "../components/layout";
import SEO from '../components/seo';

const _ = require("lodash")
/* eslint-disable import/first */

const Pagination = (props) => (
  <div className="pagination -post">
    <ul>
      {(props.next && props.next.frontmatter.template === 'blog-post') && (
        <li>
          <Link to={props.next.frontmatter.slug} rel="next">
            <p><span className="icon -left"><RiArrowLeftLine /></span> 次の記事</p>
            <span className="page-title">{props.next.frontmatter.title}</span>
          </Link>
        </li>
      )}
      {(props.previous && props.previous.frontmatter.template === 'blog-post') && (
        <li>
          <Link to={props.previous.frontmatter.slug} rel="prev">
            <p>前の記事 <span className="icon -right"><RiArrowRightLine /></span></p>
            <span className="page-title">{props.previous.frontmatter.title}</span>
          </Link>
        </li>
      )}
    </ul>
  </div>
)

const Post = ({ data, pageContext }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, excerpt } = markdownRemark
  const Image = frontmatter.featuredImage ? frontmatter.featuredImage.childImageSharp.fluid : ""
  const { previous, next } = pageContext
  const postUrl = data.site.siteMetadata.siteUrl + frontmatter.slug + `/`

  let props = {
    previous,
    next
  }

  return (
    <Layout className="page">
      <SEO
        title={frontmatter.title + " | " + data.site.siteMetadata.title}
        description={frontmatter.description ? frontmatter.description : excerpt}
        image={frontmatter.featuredImage.publicURL || data.site.siteMetadata.siteUrl + data.ogp.edges[0].node.publicURL}
        article={true}
        norobots={frontmatter.norobots}
      />
      <article className="blog-post" id="post-top">
        <header className="featured-banner">
          <section className="article-header">
            <h1>{frontmatter.title}</h1>
            <div className="post-meta-header">
              <div>
                { }
              </div>
              <div className="post-meta">
                <div className="meta-date">
                  <p><RiCalendar2Line size={18} style={{ marginRight: "2px", verticalAlign: "-10%" }} /> {frontmatter.date} </p>
                  {frontmatter.modified
                    && <p><RiRefreshLine size={18} style={{ color: "limegreen", verticalAlign: "-10%", marginRight: "2px" }} /> {frontmatter.modified}</p>
                  }
                </div>
                <p className="breadcrumb">
                  <RiHome2Line />
                  <Link to="/"> ホーム / </Link>
                  <Link to={`/cat/${_.kebabCase(frontmatter.category)}/`}>
                    <span className="meta-category">{frontmatter.category}</span>
                  </Link>
                </p>
              </div>
            </div>
          </section>
          {Image ? (
            <Img
              fluid={Image}
              objectFit="cover"
              objectPosition="50% 50%"
              alt={frontmatter.title + ' - Featured image'}
              className="featured-image"
            />
          ) : ""}
        </header>

        {data.markdownRemark.tableOfContents &&
          <div id="toc">
            <p style={{color:"#06B6C8",fontSize:"2.2rem"}}><RiMenu2Fill size="24" style={{marginRight:".45rem",paddingTop:"8px"}} />目次</p>
            <div className="toc-wrap"

              dangerouslySetInnerHTML=
              {{
                __html: data.markdownRemark.tableOfContents
              }}
            />
          </div>
        }

        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />

        <div className="post-meta-footer">
          <div className="meta-categories">
            <Link to={`/cat/${_.kebabCase(frontmatter.category)}/`}>
              <span className="meta-category">{frontmatter.category}</span>
            </Link>
          </div>
          <div className="meta-tags">
            {frontmatter.tags.map(tags => {
              return (
                <Link to={`/tags/${_.kebabCase(tags)}/`}>
                  <span className="meta-tag"># {tags}</span>
                </Link>
              )
            })}
          </div>

        </div>

        <div className="share-wrap">
          <a aria-label="twitter" href={"http://twitter.com/share?url=" + postUrl + "&text=" + frontmatter.title} target="_blank" rel="noreferrer" className="tw-btn"><RiTwitterLine /> </a>
          <a aria-label="facebook" href={"http://www.facebook.com/share.php?u=" + postUrl} target="_blank" rel="noreferrer" className="fb-btn"><RiFacebookLine /> </a>
          <a aria-label="line" href={"http://line.me/R/msg/text/?" + frontmatter.title + " " + postUrl} target="_blank" rel="noreferrer" className="line-btn"><RiLineFill /> </a>
          <a aria-label="hatena" href={"https://b.hatena.ne.jp/entry/" + postUrl} className="hatena-btn" target="_blank" rel="noreferrer" data-hatena-bookmark-title={frontmatter.title} title="はてブ">B!</a>
          <a aria-label="pinterest" href={"//pinterest.com/pin/create/button/?url=" + postUrl} target="_blank" class="pin-btn" rel="noreferrer"><RiPinterestLine /> </a>
        </div>

        <ArticleJsonLd
          url={postUrl}
          headline={frontmatter.title}
          images=
          {data.site.siteMetadata.siteUrl + frontmatter.featuredImage.publicURL}
          datePublished={frontmatter.date}
          dateModified={frontmatter.date}
          authorName={data.site.siteMetadata.author}
          publisherName={data.site.siteMetadata.title}
          publisherLogo={data.site.siteMetadata.iconimage}
          description={frontmatter.description}
          overrides={{
            '@type': 'Article',
          }}
        />

        <BreadcrumbJsonLd
          itemListElements={[
            {
              position: 1,
              name: 'Home',
              item: `${data.site.siteMetadata.siteUrl}`,
            },
            {
              position: 2,
              name: `${frontmatter.category}`,
              item: `${`${data.site.siteMetadata.siteUrl}/cat/${_.kebabCase(frontmatter.category)}/`}`,
            },
            {
              position: 3,
              name: `${frontmatter.title}`,
              item: `${postUrl}`,
            },
          ]}
        />

        <script>
          {`
          (function(c,l,a,r,i,t,y){
              c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
              y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
          })(window, document, "clarity", "script", "4fd90fjpca");
        `}
        </script>

      </article>
      {(previous || next) && (
        <Pagination {...props} />
      )}

      <button onClick={() => scrollTo('#___gatsby')} aria-label="トップへ" className="back-to-top"></button>
    </Layout>
  )
}

export default Post

export const pageQuery = graphql`
  query BlogPostQuery($id: String!) {
    site {
      siteMetadata {
        siteUrl
        title
        iconimage
        author
        ogp
      }
    }
    markdownRemark(
      id: { eq: $id }
    ) {
      id
      html
      excerpt(pruneLength: 148)
      tableOfContents(pathToSlugField: "frontmatter.slug")
      frontmatter {
        date(formatString: "YYYY/MM/DD")
        modified(formatString: "YYYY/MM/DD")
        slug
        title
        description
        category
        tags
        draft
        norobots
        featuredImage {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1024, maxHeight: 576, quality: 80, srcSetBreakpoints: [350, 700, 1050, 1400]) {
              ...GatsbyImageSharpFluidLimitPresentationSize
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
            sizes {
              src
            }
          }
        }
      }
    }
    ogp: allFile(filter: {relativePath: {eq: "default-eye.jpg"}}) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`